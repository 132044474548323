






import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Client from '@/shared/modules/client/client.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import __ from '@/shared/helpers/__'
import { FieldTypes } from '@/shared/components/form/field-types'
import IClientResponse from '@/shared/modules/client/client-response.interface'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'

@Component({
  components: { Form, PageHeader },
  methods: { __ }
})
export default class ClientCreateDialog extends Vue {
  @Prop() meta!: any

  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/clients`)
    .setFiles(true)
    .setModel(Client)
    .setFields([
      new Field()
        .setKey('first_name')
        .setEntryKey('firstName')
        .setTitle(__('company.views.clients.form.client-name')),
      // new Field()
      //   .setEntryKey('lastName')
      //   .setKey('last_name')
      //   .setTitle(__('company.views.clients.form.last_name'))
      //   .setSize(FieldSizes.half),
      // new Field()
      //   .setType(FieldTypes.email)
      //   .setKey('email')
      //   .setTitle(__('company.views.clients.form.email')),
    ])
    .setSubmit({
      text: __('company.views.clients.create.form.submit')
    })
    .setOnSuccess((data: IClientResponse, client: Client) => {
      this.$store.dispatch(GlobalActions.showSnackBar, {
        type: SnackBarTypes.success,
        message: __('company.views.clients.create.form.on-success'),
      })
      this.$store.dispatch(GlobalActions.closeDialog)
      this.meta.onSuccess && this.meta.onSuccess(client)
    })

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
