








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import Project from '@/shared/modules/project/models/project.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field, { FieldSizes } from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import ObjectField from '@/shared/classes/form/fields/object-field'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import AppendMap from '@/company/components/views/projects/AppendMap.vue'
import SelectFromMap from '@/company/components/views/projects/SelectFromMap.vue'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import _ from 'lodash'
import ClientCreate from '@/company/components/clients/ClientCreateAppend.vue'
import SelectField from '@/shared/classes/form/fields/Select/select-field'
import { getVersion } from '@/shared/helpers/version.helper'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ProjectsCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/projects`)
    .setModel(Project)
    .setInjectValues({
      company_uuid: this.company.uuid
    })
    .setFields([
      new Field()
        .setKey('name')
        .setTitle(__('company.views.projects.form.details.name'))
        .isRequired(),
      new Field()
        .setType(FieldTypes.textArea)
        .setKey('description')
        .setTitle(__('company.views.projects.form.details.description')),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('work_start_at')
        .setEntryKey('workStartAt')
        .setTitle(__('company.views.projects.form.details.work-start-at'))
        .setSize(FieldSizes.half),
      new Field()
        .setType(FieldTypes.datePicker)
        .setKey('work_end_at')
        .setEntryKey('workEndAt')
        .setTitle(__('company.views.projects.form.details.work-end-at'))
        .setSize(FieldSizes.half),
      new SearchableField()
        .setKey('client_uuid')
        .setEntryKey('clientUuid')
        .setTitle(__('company.views.projects.form.details.client'))
        .setAppend(ClientCreate)
        .loadItems({ endpoint: `company/${ this.company.slug }/clients`, value: 'uuid', title: 'full_name', perPage: 20 }),
      new SearchableField()
        .setKey('responsible_uuid')
        .setEntryKey('responsibleUuid')
        .setTitle(__('company.views.projects.form.details.responsible'))
        .loadItems({ endpoint: `company/${ this.company.slug }/employees`, value: 'uuid', title: 'full_name', perPage: 20 }),
      new ObjectField()
        .setKey('budget')
        .setTitle(__('company.views.projects.form.budget.title'))
        .setChildren([
          new Field()
            .setType(FieldTypes.number)
            .setKey('hourly_price')
            .setEntryKey('hourlyPrice')
            .setTitle(__('company.views.projects.form.budget.hourly-price'))
            .setSize(FieldSizes.quarter),
          new Field()
            .setType(FieldTypes.number)
            .setKey('agreed_price')
            .setEntryKey('agreedPrice')
            .setTitle(__('company.views.projects.form.budget.agreed-price'))
            .setSize(FieldSizes.quarter),
          new Field()
            .setType(FieldTypes.number)
            .setKey('estimated_hours')
            .setEntryKey('estimatedHours')
            .setTitle(__('company.views.projects.form.budget.estimated-hours'))
            .setSize(FieldSizes.quarter),
          new Field()
            .setType(FieldTypes.number)
            .setKey('extra_price')
            .setEntryKey('extraPrice')
            .setTitle(__('company.views.projects.form.budget.extra-price'))
            .setSize(FieldSizes.quarter),
        ]),
      new ObjectField()
        .setKey('location')
        .setTitle(__('company.views.projects.form.location.title'))
        .setChildren([
          new Field()
            .setKey('street')
            .setTitle(__('company.views.projects.form.location.street'))
            .setAppend(AppendMap)
            .setSize(FieldSizes.threeQuarters),
          new Field()
            .setKey('number')
            .setTitle(__('company.views.projects.form.location.number'))
            .setSize(FieldSizes.quarter),
          new Field()
            .setKey('post_code')
            .setEntryKey('postCode')
            .setTitle(__('company.views.projects.form.location.post-code'))
            .setSize(FieldSizes.half),
          new Field()
            .setKey('city')
            .setTitle(__('company.views.projects.form.location.city'))
            .setSize(FieldSizes.half),
          new Field()
            .setKey('municipality')
            .setTitle(__('company.views.projects.form.location.municipality'))
            .setSize(FieldSizes.fourTwelfth),
          new Field()
            .setKey('gnr')
            .setTitle(getVersion() === 'no' ? __('company.views.projects.form.location.gnr') : __('company.views.projects.form.location.gnr-lt'))
            .setSize(FieldSizes.fourTwelfth),
          new Field()
            .setKey('bnr')
            .setTitle(getVersion() === 'no' ? __('company.views.projects.form.location.bnr') : __('company.views.projects.form.location.bnr-lt'))
            .setSize(FieldSizes.fourTwelfth),
          new SearchableField()
              .setKey('country_uuid')
              .setEntryKey('countryUuid')
              .setTitle(__('company.views.projects.form.location.country'))
              .loadItems({ endpoint: '/countries', value: 'uuid', title: 'name', perPage: 20 }),
          new Field()
            .setType(FieldTypes.number)
            .setKey('lat')
            .setTitle(__('company.views.projects.form.location.lat'))
            .setSize(FieldSizes.quarter)
            .isRequired(),
          new Field()
            .setType(FieldTypes.number)
            .setKey('lng')
            .setTitle(__('company.views.projects.form.location.lng'))
            .setSize(FieldSizes.quarter)
            .isRequired(),
          new Field()
            .setType(FieldTypes.number)
            .setKey('radius')
            .setTitle(__('company.views.projects.form.location.radius'))
            .setSize(FieldSizes.half)
            .isRequired(),
        ]),
        new SelectField()
          .setKey('status_uuid')
          .setEntryKey('statusUuid')
          .setTitle(__('company.views.projects.form.details.status'))
          .loadItems({ endpoint: `statuses/model/${ Project.ALIAS }`, value: 'uuid', title: 'name', perPage: 20 })
          .setSize(FieldSizes.half)
          .isRequired()
    ])
    .setSubmit({
      text: __('company.views.projects.create.form.submit')
    })
    .setBeforeSubmit(this.beforeSubmit)
    .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.projectsIndex })
  }

  private beforeSubmit(): Promise<any>|any {
    if (this.form.data.location.lng && this.form.data.location.lat) return
    const { street, number, post_code, city, country_uuid } = this.form.data.location
    if (street || number || post_code || city || country_uuid) {
      const address = SelectFromMap.generateAddress(this.form)
      let query = `address=${ address }`
      if (country_uuid) {
        query += `&components=country:${ country_uuid }`
      }
      return SelectFromMap.request(query, this.$store.getters[GlobalGetters.getCurrentLocale])
        .then((response: any) => {
          this.form.data = {
            ...this.form.data,
            location: {
              ...this.form.data.location,
              lat: _.get(response, 'data.results.0.geometry.location.lat', 0),
              lng: _.get(response, 'data.results.0.geometry.location.lng', 0),
            }
          }
        })
    }
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.projects.create.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
