





import { Component, Prop, Vue } from 'vue-property-decorator'
import { GlobalActions } from '@/shared/store/global/global.actions'
import Client from '@/shared/modules/client/client.model'
import ClientCreateDialog from '@/company/components/clients/ClientCreateDialog.vue'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'

@Component
export default class ClientCreate extends Vue {
  openClientCreate(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: ClientCreateDialog,
      maxWidth: 550,
      meta: {
        onSuccess: (client: Client) => this.$emit('setValue', client.uuid)
      }
    })
  }
}
